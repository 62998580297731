import React from 'react'
import styled from 'styled-components'

// Components
import Layout from '../components/Layout'

const Wrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: ${({ theme }) => theme.breakpoints.sm};
  padding: 0 ${({ theme }) => theme.spacings.large};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme }) => theme.spacings.small};
  }
`

const Privacy = () => {
  return (
    <>
      <Layout subTitle="Privacy Policy">
        <Wrapper>template</Wrapper>
      </Layout>
    </>
  )
}

export default Privacy
